<div class="min-h-screen flex flex-row bg-gray-100 w-full">
  <div class="flex flex-col bg-white overflow-hidden w-full">
    <div class="flex items-center justify-center h-20 shadow-md">
      <img
        title="logo"
        src="assets/images/logo-optinoise-db.svg"
        class="h-16"
      />
    </div>
    <ul class="flex flex-col py-4">
      <li>
        <a
          href="#"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-home"></i
          ></span>
          <span class="text-sm font-medium">Dashboard</span>
        </a>
      </li>
      <li *ngIf="userRole === 'ADMIN' || userRole === 'EXPERT'">
        <a
          [routerLink]="['/users']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-user"></i
          ></span>
          <span class="text-sm font-medium">Utilisateurs</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/manufacturers']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-store-alt"></i
          ></span>
          <span class="text-sm font-medium">Fabricants</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/operational-models']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-store-alt"></i
          ></span>
          <span class="text-sm font-medium">Modèle opérationnel</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/models']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-grid"></i
          ></span>
          <span class="text-sm font-medium">Modèle</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/heights']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-ruler"></i
          ></span>
          <span class="text-sm font-medium">Hauteur</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/mod2']"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-notepad"></i
          ></span>
          <span class="text-sm font-medium">MOD2</span>
        </a>
      </li>
      <li>
        <a
          href="#"
          class="
            flex flex-row
            items-center
            h-12
            transform
            hover:translate-x-2
            transition-transform
            ease-in
            duration-200
            text-engie-blue
            hover-text-engie-blue
          "
          (click)="okta.logout()"
        >
          <span
            class="
              inline-flex
              items-center
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            ><i class="bx bx-log-out"></i
          ></span>
          <span class="text-sm font-medium">Logout</span>
        </a>
      </li>
    </ul>
  </div>
</div>
