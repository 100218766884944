<div class="p-4 md:p-16">
  <div class="border-2 rounded-sm shadow-md p-8">
    <label nz-checkbox>Afficher les commentaires</label>
    <label nz-checkbox>Afficher les documents</label>
  </div>
  <div class="border-2 rounded-sm shadow-md p-8 mt-8">
    <div class="text-xl my-4 mx-auto w-max">MOD2</div>
    <nz-table
      #TableCard
      [nzData]="currentMod2"
      [nzShowPagination]="false"
      [nzBordered]="true"
      nzTheme="twotone"
    >
      <thead>
        <tr>
          <th>Fabriquant</th>
          <th>Modèle</th>
          <th>Modèle opérationnel</th>
          <th>Hauteur moyeu</th>
          <th>Statut</th>
          <th>Date d'ajout</th>
          <th>Créé par</th>
          <th>Validé par</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of TableCard.data">
          <td>{{ data.manufacturer.name }}</td>
          <td>{{ data.model.name }}</td>
          <td>{{ data.operational_model.name }}</td>
          <td>{{ data.height.value }}</td>
          <td>{{ data.status_label }}</td>
          <td>{{ data.createdAt }}</td>
          <td>{{ data.creator.email }}</td>
          <td>{{ data.validator ? data.validator.email : "" }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div class="border-2 rounded-sm shadow-md p-8 mt-8">
    <div class="text-xl my-4 mx-auto w-max">MOD2 Performances</div>
    <nav class="flex mb-4">
      <div class="flex items-center justify-end w-full">
        <button nz-button nzType="dashed" (click)="addNewLine()">
          <div class="flex items-center">
            <i nz-icon nzType="appstore-add"></i>
            <p class="m-0 ml-2">Ajouter une ligne</p>
          </div>
        </button>
      </div>
    </nav>
    <nz-table
      #performancesTable
      [nzData]="currentMod2PerformanceList"
      [nzBordered]="true"
      [nzPageSize]="30"
      [nzSize]="'small'"
      nzShowSizeChanger
    >
      <thead>
        <tr>
          <th [nzAlign]="'center'">
            <p>vHH</p>
            <p>[ m/s ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Puissance générée</p>
            <p>[ kW ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Coefficient de poussée</p>
            <p>[-]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw Global</p>
            <p>[ dB(A) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 63Hz</p>
            <p></p>
            [ dB(Lin) ]
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 125Hz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 250Hz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 500Hz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 1kHz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 2kHz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 4kHz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'">
            <p>Lw 8kHz</p>
            <p>[ dB(Lin) ]</p>
          </th>
          <th [nzAlign]="'center'"><p>Action</p></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of performancesTable.data">
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else vhhDBLinInputTpl"
            >
              {{ data.vhh | number: "1.3-3" }}
            </ng-container>
            <ng-template #vhhDBLinInputTpl>
              <input
                title="vhh"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.vhh"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="
                !editCache[data.id].edit;
                else generated_powerDBLinInputTpl
              "
            >
              {{ data.generated_power | number: "1.3-3" }}
            </ng-container>
            <ng-template #generated_powerDBLinInputTpl>
              <input
                title="generated_power"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.generated_power"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="
                !editCache[data.id].edit;
                else coefficient_trustDBLinInputTpl
              "
            >
              {{ data.coefficient_trust | number: "1.3-3" }}
            </ng-container>
            <ng-template #coefficient_trustDBLinInputTpl>
              <input
                title="coefficient_trust"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.coefficient_trust"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_globalDBLinInputTpl"
            >
              {{ data.lw_global | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_globalDBLinInputTpl>
              <input
                title="lw_global"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_global"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_63_hzDBLinInputTpl"
            >
              {{ data.lw_63_hz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_63_hzDBLinInputTpl>
              <input
                title="lw_63_hz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_63_hz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_125_hzDBLinInputTpl"
            >
              {{ data.lw_125_hz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_125_hzDBLinInputTpl>
              <input
                title="lw_125_hz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_125_hz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_250_hzDBLinInputTpl"
            >
              {{ data.lw_250_hz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_250_hzDBLinInputTpl>
              <input
                title="lw_250_hz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_250_hz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_500_hzDBLinInputTpl"
            >
              {{ data.lw_500_hz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_500_hzDBLinInputTpl>
              <input
                title="lw_500_hz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_500_hz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_1_khzDBLinInputTpl"
            >
              {{ data.lw_1_khz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_1_khzDBLinInputTpl>
              <input
                title="lw_1_khz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_1_khz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_2_khzDBLinInputTpl"
            >
              {{ data.lw_2_khz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_2_khzDBLinInputTpl>
              <input
                title="lw_2_khz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_2_khz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_4_khzDBLinInputTpl"
            >
              {{ data.lw_4_khz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_4_khzDBLinInputTpl>
              <input
                title="lw_4_khz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_4_khz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container
              *ngIf="!editCache[data.id].edit; else lw_8_khzDBLinInputTpl"
            >
              {{ data.lw_8_khz | number: "1.3-3" }}
            </ng-container>
            <ng-template #lw_8_khzDBLinInputTpl>
              <input
                title="lw_8_khz"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data.lw_8_khz"
              />
            </ng-template>
          </td>
          <td [nzAlign]="'center'">
            <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
              <span
                matTooltip="Mettre à jour"
                class="
                  inline-flex
                  items-center
                  cursor-pointer
                  justify-center
                  h-12
                  w-12
                  text-lg text-engie-blue
                "
                (click)="startEdit(data.id)"
                ><i class="bx bxs-edit"></i
              ></span>
              <span
                matTooltip="Supprimer"
                class="
                  inline-flex
                  items-center
                  cursor-pointer
                  justify-center
                  h-12
                  w-12
                  text-lg text-engie-danger
                "
                (click)="openDeleteMod2PerformanceModal(data.id)"
                ><i class="bx bx-trash-alt"></i
              ></span>
            </ng-container>
            <ng-template #saveTpl>
              <span
                matTooltip="Sauvegarder"
                class="
                  inline-flex
                  items-center
                  cursor-pointer
                  justify-center
                  h-12
                  w-12
                  text-lg text-engie-green
                "
                (click)="saveEdit(data.id)"
                ><i class="bx bx-save"></i
              ></span>
              <span
                matTooltip="Annuler"
                class="
                  inline-flex
                  items-center
                  cursor-pointer
                  justify-center
                  h-12
                  w-12
                  text-lg text-engie-danger
                "
                nz-popconfirm
                nzPopconfirmTitle="Annuler les modifications ?"
                nzPopconfirmPlacement="bottom"
                (nzOnConfirm)="cancelEdit(data.id)"
                ><i class="bx bx-redo"></i
              ></span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
