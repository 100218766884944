<div class="p-4 md:p-16">
  <nav class="flex mb-4">
    <div class="flex items-center justify-end w-full">
      <button nz-button nzType="dashed" (click)="redirectToCreate()">
        <div class="flex items-center">
          <i nz-icon nzType="folder-add"></i>
          <p class="m-0 ml-2">Ajouter un MOD2 par formulaire</p>
        </div>
      </button>
    </div>
  </nav>
  <div class="border-2 rounded-sm shadow-md p-8 mt-8">
    <div class="text-xl my-4 mx-auto w-max">MOD2 validés</div>
    <nz-table
      #filterValidatedTable
      [nzData]="mod2ValidatedListDataFiltered"
      nzTableLayout="fixed"
      [nzSize]="'small'"
      nzShowSizeChanger
      [nzPageSize]="10"
      [nzBordered]="true"
    >
      <thead>
        <tr>
          <th
            nzCustomFilter
            *ngFor="let column of listOfColumns"
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
            [nzShowFilter]="false"
            [nzShowSort]="column.name === 'Actions' ? false : true"
            [nzAlign]="column.name === 'Actions' ? 'center' : 'left'"
            nzTheme="twotone"
          >
            {{ column.name }}
            <nz-filter-trigger *ngIf="column.name === 'Fabriquant'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('MANUFACTURER', 'VALIDATED')"
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Modèle'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('MODEL', 'VALIDATED')"
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Modèle opérationnel'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('OPERATIONAL_MODEL', 'VALIDATED')"
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Hauteur moyeu'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('HEIGHT', 'VALIDATED')"
              ></i>
            </nz-filter-trigger>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filterValidatedTable.data">
          <td>{{ data.manufacturer.name }}</td>
          <td>{{ data.model.name }}</td>
          <td>{{ data.operational_model.name }}</td>
          <td>{{ data.height.value }}</td>
          <td class="text-engie-green">{{ data.status_label }}</td>
          <td [nzAlign]="'center'">
            <span
              matTooltip="Détails"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-green
              "
              (click)="onDetailsMod2Modal(data.id)"
              ><i class="bx bx-detail"></i
            ></span>
            <span
              matTooltip="Mettre à jour"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-blue
              "
              (click)="redirectToCreate(data.id, data.creator.id)"
              ><i class="bx bxs-edit"></i
            ></span>
            <span
              matTooltip="Supprimer"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-danger
              "
              (click)="openDeleteMod2Modal(data.id, data.creator.id)"
              ><i class="bx bx-trash-alt"></i
            ></span>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="border-2 rounded-sm shadow-md p-8 mt-8">
    <div class="text-xl my-4 mx-auto w-max">MOD2 en attente de validation</div>
    <nz-table
      #filterAwaitValidationTable
      [nzData]="mod2AwaitValidationListDataFiltered"
      nzTableLayout="fixed"
      [nzSize]="'small'"
      nzShowSizeChanger
      [nzPageSize]="10"
      [nzBordered]="true"
    >
      <thead>
        <tr>
          <th
            nzCustomFilter
            *ngFor="let column of listOfColumns"
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
            [nzShowFilter]="false"
            [nzShowSort]="column.name === 'Actions' ? false : true"
            [nzAlign]="column.name === 'Actions' ? 'center' : 'left'"
            nzTheme="twotone"
          >
            {{ column.name }}
            <nz-filter-trigger *ngIf="column.name === 'Fabriquant'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="
                  showFilterModal('MANUFACTURER', 'WAITING_FOR_VALIDATION')
                "
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Modèle'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('MODEL', 'WAITING_FOR_VALIDATION')"
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Modèle opérationnel'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="
                  showFilterModal('OPERATIONAL_MODEL', 'WAITING_FOR_VALIDATION')
                "
              ></i>
            </nz-filter-trigger>
            <nz-filter-trigger *ngIf="column.name === 'Hauteur moyeu'">
              <i
                nz-icon
                nzType="filter"
                nzTheme="twotone"
                (click)="showFilterModal('HEIGHT', 'WAITING_FOR_VALIDATION')"
              ></i>
            </nz-filter-trigger>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filterAwaitValidationTable.data">
          <td>{{ data.manufacturer.name }}</td>
          <td>{{ data.model.name }}</td>
          <td>{{ data.operational_model.name }}</td>
          <td>{{ data.height.value }}</td>
          <td class="text-engie-orange">{{ data.status_label }}</td>
          <td [nzAlign]="'center'">
            <span
              matTooltip="Détails"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-green
              "
              (click)="onDetailsMod2Modal(data.id)"
              ><i class="bx bx-detail"></i
            ></span>
            <span
              *ngIf="
                roleCheck.getUserRole() === 'ADMIN' ||
                roleCheck.getUserRole() === 'EXPERT'
              "
              matTooltip="Valider le mod2"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-green
              "
              (click)="openValidateMod2Modal(data.id, 'VALIDATED')"
              ><i class="bx bxs-check-circle"></i
            ></span>
            <span
              *ngIf="
                roleCheck.getUserRole() === 'ADMIN' ||
                roleCheck.getUserRole() === 'EXPERT'
              "
              matTooltip="Refuser le mod2"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-danger
              "
              (click)="openValidateMod2Modal(data.id, 'REFUSED')"
              ><i class="bx bxs-x-circle"></i
            ></span>
            <span
              matTooltip="Mettre à jour"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-blue
              "
              (click)="redirectToCreate(data.id, data.creator.id)"
              ><i class="bx bxs-edit"></i
            ></span>
            <span
              matTooltip="Supprimer"
              class="
                inline-flex
                items-center
                cursor-pointer
                justify-center
                h-12
                w-12
                text-lg text-engie-danger
              "
              (click)="openDeleteMod2Modal(data.id, data.creator.id)"
              ><i class="bx bx-trash-alt"></i
            ></span>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleFilterModalManufacturer"
  nzTitle="Filtre"
  (nzOnCancel)="handleCancel('MANUFACTURER')"
  (nzOnOk)="handleOk('MANUFACTURER')"
>
  <ng-container *nzModalContent>
    <div class="block">
      <p class="text-bold text-engie-blue">
        Selectionnez une ou plusieurs valeurs
      </p>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Fabriquants"
        [(ngModel)]="listOfSelectedManufacturerValues"
        class="w-2/3"
      >
        <nz-option
          *ngFor="let item of manufacturerModelList"
          [nzLabel]="item.name"
          [nzValue]="item.name"
        ></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList
        >Et {{ selectedList.length }} selectionné(s)</ng-template
      >
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVisibleFilterHeight"
  nzTitle="Filtre"
  (nzOnCancel)="handleCancel('HEIGHT')"
  (nzOnOk)="handleOk('HEIGHT')"
>
  <ng-container *nzModalContent>
    <div class="block">
      <p class="text-bold text-engie-blue">
        Selectionnez une ou plusieurs valeurs
      </p>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Hauteurs"
        [(ngModel)]="listOfSelectedHeightValues"
        class="w-2/3"
      >
        <nz-option
          *ngFor="let item of heightModelList"
          [nzLabel]="item.value"
          [nzValue]="item.value"
        ></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList
        >Et {{ selectedList.length }} selectionné(s)</ng-template
      >
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVisibleFilterModalModel"
  nzTitle="Filtre"
  (nzOnCancel)="handleCancel('MODEL')"
  (nzOnOk)="handleOk('MODEL')"
>
  <ng-container *nzModalContent>
    <div class="block">
      <p class="text-bold text-engie-blue">
        Selectionnez une ou plusieurs valeurs
      </p>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Modèles"
        [(ngModel)]="listOfSelectedModelValues"
        class="w-2/3"
      >
        <nz-option
          *ngFor="let item of modelModelList"
          [nzLabel]="item.name"
          [nzValue]="item.name"
        ></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList
        >Et {{ selectedList.length }} selectionné(s)</ng-template
      >
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVisibleFilterOperationalModel"
  nzTitle="Filtre"
  (nzOnCancel)="handleCancel('OPERATIONAL_MODEL')"
  (nzOnOk)="handleOk('OPERATIONAL_MODEL')"
>
  <ng-container *nzModalContent>
    <div class="block">
      <p class="text-bold text-engie-blue">
        Selectionnez une ou plusieurs valeurs
      </p>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Modèles opérationnels"
        [(ngModel)]="listOfSelectedOperationalValues"
        class="w-2/3"
      >
        <nz-option
          *ngFor="let item of operationalModelList"
          [nzLabel]="item.name"
          [nzValue]="item.name"
        ></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList
        >Et {{ selectedList.length }} selectionné(s)</ng-template
      >
    </div>
  </ng-container>
</nz-modal>
