import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { ManufacturerModel } from 'src/app/shared/models/manufacturer-model';
import { ManufacturerService } from 'src/app/shared/services/manufacturer.service';

@Component({
  selector: 'app-manufacturer-list',
  templateUrl: './manufacturer-list.component.html',
  styleUrls: ['./manufacturer-list.component.scss'],
})
export class ManufacturerListComponent implements OnInit {
  public manufacturerList: ManufacturerModel[] = [];
  public manufacturerForm!: FormGroup;
  public isSubmitted: boolean = false;
  private currentManufacturerId?: string;

  public isVisibleCreateModalManufacturer = false;

  constructor(
    private manufacturerService: ManufacturerService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    this.manufacturerForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getManufacturers();
  }

  getManufacturers() {
    this.manufacturerList = [];
    this.manufacturerService.listManufacturers().subscribe(
      (res) => {
        if (res.data && res.data.listManufacturers) {
          res.data.listManufacturers.items.forEach(
            (element: ManufacturerModel) => {
              this.manufacturerList.push(element);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisibleCreateModalManufacturer = false;
  }

  handleOk(): void {
    this.isSubmitted = true;
    if (this.manufacturerForm.valid) {
      const manufacturer = {
        name: this.manufacturerFormControls.name.value,
      };
      if (this.currentManufacturerId) {
        this.manufacturerService
          .updateManufacturer(this.currentManufacturerId, manufacturer)
          .subscribe(
            (res) => {
              if (res.data && res.data.updateManufacturer.id) {
                this.message.success('Fabriquant mis à jour avec succès', {
                  nzDuration: 2500,
                });
                this.isVisibleCreateModalManufacturer = false;
                this.getManufacturers();
              } else {
                this.message.error('Une erreur est survenue', {
                  nzDuration: 2500,
                });
              }
            },
            (err) => {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
              console.log(err);
            }
          );
      } else {
        this.manufacturerService.addManufacturer(manufacturer).subscribe(
          (res) => {
            if (res.data && res.data.createManufacturer.id) {
              this.message.success('Fabriquant ajouté avec succès', {
                nzDuration: 2500,
              });
              this.isVisibleCreateModalManufacturer = false;
              this.getManufacturers();
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', {
              nzDuration: 2500,
            });
            console.log(err);
          }
        );
      }
    }
  }

  openDeleteManufacturerModal(manufacturerId: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer ce fabriquant ?',
      nzContent: "Attention ! l'action n'est pas reverssible",
      nzOnOk: () => {
        this.deleteManufacturer(manufacturerId);
      },
    });
  }

  deleteManufacturer(manufacturerId: string) {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.manufacturerService.deleteManufacturer(manufacturerId).subscribe(
      (res) => {
        if (res.data && res.data.deleteManufacturer.id) {
          this.message.remove(loading);
          this.message.success('Fabriquant supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getManufacturers();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  showCreateModal(manufacturerModel?: ManufacturerModel) {
    if (manufacturerModel) {
      this.currentManufacturerId = manufacturerModel.id;
      this.manufacturerFormControls.name.setValue(manufacturerModel.name);
    } else {
      this.currentManufacturerId = undefined;
      this.manufacturerFormControls.name.setValue('');
    }
    this.isVisibleCreateModalManufacturer = true;
  }

  get manufacturerFormControls() {
    return this.manufacturerForm.controls;
  }
}
