export const AppConstants = Object.freeze({
  mod2Status:{
    waitingForValidation: {
      code: "WAITING_FOR_VALIDATION",
      label: "En attente de validation"
    },
    validated: {
      code: "VALIDATED",
      label: "Validé"
    }
  }
})