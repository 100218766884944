import { Component, HostListener, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { SidenavService } from './shared/services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'WTG';
  public isLargeScreen: Boolean = false;
  @ViewChild('sidenav', { static: true })
  public sidenav!: MatSidenav;
  isAuthenticated: boolean = false;
  constructor(
    public router: Router,
    private sideNavService: SidenavService,
    public oktaAuth: AuthService
  ) {}

  ngOnInit() {
    this.oktaAuth.$isAuthenticated.subscribe(async (val) => {
      this.isAuthenticated = val;
      await this.oktaAuth.setUserRole();
      this.sideNavService.sideNavToggleSubject.subscribe(() => {
        this.sidenav.toggle();
      });
      if (window.screen.width >= 1024) {
        this.isLargeScreen = true;
        this.sidenav.open();
      } else {
        this.isLargeScreen = false;
        this.sidenav.close();
      }
      if (this.router.url == '/') {
        // TODO rediriger vers la page unauthorized ou home
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    if (event.target.innerWidth >= 1024) {
      this.isLargeScreen = true;
      //this.sidenav.open();
    } else {
      this.isLargeScreen = false;
      //this.sidenav.close();
    }
  }
}
