import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Mod2Service {
  constructor(public http: HttpClient) {}

  public listMod2s(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listMod2s {
            items {
              id
              manufacturer {
                id
                name
              }
              model {
                id
                name
              }
              operational_model {
                id
                name
              }
              height {
                id
                value
              }
              creator {
                id
                email
              }
              validator {
                id
                email
              }
              status
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getMod2(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          getMod2(id:"${id}") {
            id
            manufacturer {
              id
              name
            }
            model {
              id
              name
            }
            operational_model {
              id
              name
            }
            height {
              id
              value
            }
            creator {
              id
              email
            }
            validator {
              id
              email
            }
            status
            createdAt
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public addMod2(mod2Model: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          createMod2(input: {
            manufacturer_id: "${mod2Model.manufacturer}", 
            model_id: "${mod2Model.model}", 
            operational_model_id: "${mod2Model.operationalModel}", 
            height_id: "${mod2Model.height}",
            status: ${mod2Model.status},
            creator_id: "${mod2Model.creator}"
          }){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateMod2(mod2Id: string, mod2Model: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          updateMod2(input: {
            id: "${mod2Id}", 
            manufacturer_id: "${mod2Model.manufacturer}", 
            model_id: "${mod2Model.model}", 
            operational_model_id: "${mod2Model.operationalModel}", 
            height_id: "${mod2Model.height}"
          }){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateMod2Status(
    mod2Id: string,
    status: string,
    validatorId: string
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          updateMod2(input: {
            id: "${mod2Id}", 
            status: ${status},
            validator_id: "${validatorId}"
          }){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteMod2(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          deleteMod2(input:{id:"${id}"}) {id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public listMod2Performances(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listMod2Performances(filter: {mod2_id: {eq: "${id}"}}) {
            items {
              coefficient_trust
              generated_power
              id
              lw_125_hz
              lw_1_khz
              lw_250_hz
              lw_2_khz
              lw_4_khz
              lw_500_hz
              lw_63_hz
              lw_8_khz
              lw_global
              vhh
              mod2_id
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public createMod2Performances(mod2Performance: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          createMod2Performances(input: {
            coefficient_trust: ${mod2Performance.coefficient_trust},
            generated_power: ${mod2Performance.generated_power},
            lw_125_hz: ${mod2Performance.lw_125_hz},
            lw_1_khz: ${mod2Performance.lw_1_khz},
            lw_250_hz: ${mod2Performance.lw_250_hz},
            lw_2_khz: ${mod2Performance.lw_2_khz},
            lw_4_khz: ${mod2Performance.lw_4_khz},
            lw_500_hz: ${mod2Performance.lw_500_hz},
            lw_63_hz: ${mod2Performance.lw_63_hz},
            lw_8_khz: ${mod2Performance.lw_8_khz},
            lw_global: ${mod2Performance.lw_global},
            mod2_id: "${mod2Performance.mod2_id}",
            vhh: ${mod2Performance.vhh}
          }){
              id
            }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateMod2Performances(
    mod2Id: string,
    mod2Performance: any
  ): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          updateMod2Performances(input: {
            id: "${mod2Id}",
            coefficient_trust: ${mod2Performance.coefficient_trust},
            generated_power: ${mod2Performance.generated_power},
            lw_125_hz: ${mod2Performance.lw_125_hz},
            lw_1_khz: ${mod2Performance.lw_1_khz},
            lw_250_hz: ${mod2Performance.lw_250_hz},
            lw_2_khz: ${mod2Performance.lw_2_khz},
            lw_4_khz: ${mod2Performance.lw_4_khz},
            lw_500_hz: ${mod2Performance.lw_500_hz},
            lw_63_hz: ${mod2Performance.lw_63_hz},
            lw_8_khz: ${mod2Performance.lw_8_khz},
            lw_global: ${mod2Performance.lw_global},
            mod2_id: "${mod2Performance.mod2_id}",
            vhh: ${mod2Performance.vhh}
          }){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteMod2Performances(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          deleteMod2Performances(input:{id:"${id}"}) {id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
