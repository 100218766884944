import { Injectable } from '@angular/core';

@Injectable()
export class RoleCheck {
  constructor() {}

  getUserRole(): any {
    return localStorage.getItem('USER_ROLE');
  }
}
