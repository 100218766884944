<mat-toolbar
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="bg-engie-blue sticky top-0 z-50 h-20"
>
  <button
    mat-icon-button
    fxShow.sm="true"
    fxShow.xs="true"
    fxShow="false"
    class="absolute"
  >
    <img src="assets/svg/menu.svg" matTooltip="Menu" (click)="clickMenu()" />
  </button>
  <p class="mx-auto cursor-pointer text-xl text-white">WTG</p>
</mat-toolbar>
