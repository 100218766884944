import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RoleModel } from 'src/app/shared/models/role-model';
import { RoleService } from 'src/app/shared/services/role.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss'],
})
export class UserAddComponent implements OnInit {
  public userForm!: FormGroup;
  public isSubmitted: boolean = false;
  public roles: RoleModel[] = [];
  public currentUserId!: string;
  constructor(
    private roleService: RoleService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService
  ) {
    this.userForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getRoles();
    this.activatedRoute.params.subscribe((v) => {
      if (v.id) {
        this.currentUserId = v.id;
        this.userService.getUser(this.currentUserId).subscribe(
          (res) => {
            if (res.data && res.data.getUser.id) {
              this.userFormControls.firstName.setValue(
                res.data.getUser.first_name
              );
              this.userFormControls.lastName.setValue(
                res.data.getUser.last_name
              );
              this.userFormControls.email.setValue(res.data.getUser.email);
              this.userFormControls.gid.setValue(res.data.getUser.gid);
              this.userFormControls.role.setValue(res.data.getUser.role.id);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  getRoles() {
    this.roleService.listUserRoles().subscribe(
      (res) => {
        if (res.data && res.data.listUserRoles) {
          res.data.listUserRoles.items.forEach((element: any) => {
            switch (element.role) {
              case 'ADMIN':
                element.label = 'Administrateur';
                break;
              case 'USER':
                element.label = 'Utilisateur';
                break;
              case 'EXPERT':
                element.label = 'Expert';
                break;
              default:
                break;
            }
            this.roles.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^[A-Za-z0-9._%+-]+@(engie.com|external.engie.com)$/
        ),
      ]),
      gid: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z]{2}[0-9]{4}$/),
      ]),
      role: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.userForm.valid) {
      const user = {
        firstName: this.userFormControls.firstName.value,
        lastName: this.userFormControls.lastName.value,
        email: this.userFormControls.email.value,
        gid: this.userFormControls.gid.value,
        roleId: this.userFormControls.role.value,
      };
      if (this.currentUserId) {
        this.userService.updateUser(this.currentUserId, user).subscribe(
          (res) => {
            if (res.data && res.data.updateUser.id) {
              this.message.success('Utilisateur mis à jour avec succès', {
                nzDuration: 2500,
              });
              this.router.navigate(['users']);
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
            console.log(err);
          }
        );
      } else {
        this.userService.addUser(user).subscribe(
          (res) => {
            if (res.data && res.data.createUser.id) {
              this.message.success('Utilisateur ajouté avec succès', {
                nzDuration: 2500,
              });
              this.router.navigate(['users']);
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
            console.log(err);
          }
        );
      }
    }
  }

  get userFormControls() {
    return this.userForm.controls;
  }
}
