import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin } from 'rxjs';
import { AppConstants } from 'src/app/shared/dictionnary/appConstants';
import { RoleCheck } from 'src/app/shared/helpers/role-check';
import { Mod2sColumnItem } from 'src/app/shared/Interfaces/mod2s-column-interface';
import { HeightModel } from 'src/app/shared/models/height-model';
import { ManufacturerModel } from 'src/app/shared/models/manufacturer-model';
import { Mod2Model } from 'src/app/shared/models/mod2-model';
import { Mod2PerformancesModel } from 'src/app/shared/models/mod2-performances-model';
import { ModelModel } from 'src/app/shared/models/model-model';
import { OperationalModelModel } from 'src/app/shared/models/operational-model-model';
import { HeightService } from 'src/app/shared/services/height.service';
import { ManufacturerService } from 'src/app/shared/services/manufacturer.service';
import { Mod2Service } from 'src/app/shared/services/mod2.service';
import { ModelService } from 'src/app/shared/services/model.service';
import { OperationalModelService } from 'src/app/shared/services/operational-model.service';

@Component({
  selector: 'app-mod2-list',
  templateUrl: './mod2-list.component.html',
  styleUrls: ['./mod2-list.component.scss'],
})
export class Mod2ListComponent implements OnInit {
  mod2ValidatedListDataFiltered: Mod2Model[] = [];
  mod2ValidatedListData: Mod2Model[] = [];

  mod2AwaitValidationListDataFiltered: Mod2Model[] = [];
  mod2AwaitValidationListData: Mod2Model[] = [];

  // SEARCH OPTIONS
  searchModel: string = '';

  // MANUFACTURER FILTER OPTIONS
  isVisibleFilterModalManufacturer = false;
  isVisibleFilterModalManufacturerStatus = '';
  listOfSelectedManufacturerValues: string[] = [];

  // MODEL FILTER OPTIONS
  isVisibleFilterModalModel = false;
  isVisibleFilterModalModelStatus = '';
  listOfSelectedModelValues: string[] = [];

  // OPERATIONAL MODEL FILTER OPTIONS
  isVisibleFilterOperationalModel = false;
  isVisibleFilterOperationalModelStatus = '';
  listOfSelectedOperationalValues: string[] = [];

  // HEIGHT FILTER OPTIONS
  isVisibleFilterHeight = false;
  isVisibleFilterHeightStatus = '';
  listOfSelectedHeightValues: string[] = [];

  // TABLE COLUMNS OPTIONS
  listOfColumns: Mod2sColumnItem[] = [
    {
      name: 'Fabriquant',
      sortOrder: null,
      sortFn: (a: Mod2Model, b: Mod2Model) =>
        a.manufacturer.name.localeCompare(b.manufacturer.name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Modèle',
      sortOrder: null,
      sortFn: (a: Mod2Model, b: Mod2Model) =>
        a.model.name.localeCompare(b.model.name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Modèle opérationnel',
      sortOrder: null,
      sortFn: (a: Mod2Model, b: Mod2Model) =>
        a.operational_model.name.localeCompare(b.operational_model.name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Hauteur moyeu',
      sortOrder: null,
      sortFn: (a: Mod2Model, b: Mod2Model) =>
        a.height.value.localeCompare(b.height.value),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Statut',
      sortOrder: null,
      sortFn: (a: Mod2Model, b: Mod2Model) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Actions',
      sortOrder: null,
      sortFn: null,
      sortDirections: [],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
  ];

  public manufacturerModelList: ManufacturerModel[] = [];
  public operationalModelList: OperationalModelModel[] = [];
  public modelModelList: ModelModel[] = [];
  public heightModelList: HeightModel[] = [];

  constructor(
    private manufacturerService: ManufacturerService,
    private modelService: ModelService,
    private mod2Service: Mod2Service,
    private modal: NzModalService,
    private message: NzMessageService,
    private router: Router,
    private heightService: HeightService,
    private operationalModelService: OperationalModelService,
    public roleCheck: RoleCheck
  ) {
    this.getData();
  }

  ngOnInit(): void {
    this.getMod2s();
  }

  getMod2s(): void {
    this.mod2AwaitValidationListData = [];
    this.mod2ValidatedListData = [];
    this.mod2AwaitValidationListDataFiltered = [];
    this.mod2ValidatedListDataFiltered = [];
    this.mod2Service.listMod2s().subscribe(
      (res) => {
        if (res.data && res.data.listMod2s) {
          res.data.listMod2s.items.forEach((element: Mod2Model) => {
            switch (element.status) {
              case AppConstants.mod2Status.waitingForValidation.code:
                element.status_label =
                  AppConstants.mod2Status.waitingForValidation.label;
                this.mod2AwaitValidationListDataFiltered.push(element);
                break;
              case AppConstants.mod2Status.validated.code:
                element.status_label = AppConstants.mod2Status.validated.label;
                this.mod2ValidatedListDataFiltered.push(element);
                break;
              default:
                break;
            }
          });
          this.mod2AwaitValidationListDataFiltered = [
            ...this.mod2AwaitValidationListDataFiltered,
          ];
          this.mod2ValidatedListDataFiltered = [
            ...this.mod2ValidatedListDataFiltered,
          ];
          this.mod2AwaitValidationListData =
            this.mod2AwaitValidationListDataFiltered;
          this.mod2ValidatedListData = this.mod2ValidatedListDataFiltered;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getData() {
    const manufacturerObservable = this.manufacturerService.listManufacturers();
    const operationalModelObservable =
      this.operationalModelService.listOperationalModels();
    const modelObservable = this.modelService.listModels();
    const heightObservable = this.heightService.listHeights();
    forkJoin([
      manufacturerObservable,
      operationalModelObservable,
      modelObservable,
      heightObservable,
    ]).subscribe(
      (result) => {
        if (result[0].data && result[0].data.listManufacturers) {
          result[0].data.listManufacturers.items.forEach(
            (element: ManufacturerModel) => {
              this.manufacturerModelList.push(element);
            }
          );
        }
        if (result[1].data && result[1].data.listOperationalModels) {
          result[1].data.listOperationalModels.items.forEach(
            (element: OperationalModelModel) => {
              this.operationalModelList.push(element);
            }
          );
        }
        if (result[2].data && result[2].data.listModels) {
          result[2].data.listModels.items.forEach((element: ModelModel) => {
            this.modelModelList.push(element);
          });
        }
        if (result[3].data && result[3].data.listHeights) {
          result[3].data.listHeights.items.forEach((element: HeightModel) => {
            this.heightModelList.push(element);
          });
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  deleteMod2(mod2Id: string): void {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.mod2Service.deleteMod2(mod2Id).subscribe(
      (res) => {
        if (res.data && res.data.deleteMod2.id) {
          this.deleteMod2Performnces(mod2Id);
          this.message.remove(loading);
          this.message.success('Mod2 supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getMod2s();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  deleteMod2Performnces(id: string): void {
    this.mod2Service.listMod2Performances(id).subscribe(
      (res) => {
        if (res.data && res.data.listMod2Performances) {
          res.data.listMod2Performances.items.forEach(
            (element: Mod2PerformancesModel) => {
              this.mod2Service.deleteMod2Performances(element.id).subscribe(
                (res) => {},
                (err) => {}
              );
            }
          );
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showFilterModal(field: string, status: string): void {
    switch (field) {
      case 'MANUFACTURER':
        this.isVisibleFilterModalManufacturer = true;
        this.isVisibleFilterModalManufacturerStatus = status;
        break;
      case 'HEIGHT':
        this.isVisibleFilterHeight = true;
        this.isVisibleFilterHeightStatus = status;
        break;
      case 'MODEL':
        this.isVisibleFilterModalModel = true;
        this.isVisibleFilterModalModelStatus = status;
        break;
      case 'OPERATIONAL_MODEL':
        this.isVisibleFilterOperationalModel = true;
        this.isVisibleFilterOperationalModelStatus = status;
        break;
      default:
        break;
    }
  }

  handleCancel(field: string): void {
    switch (field) {
      case 'MANUFACTURER':
        this.isVisibleFilterModalManufacturer = false;
        break;
      case 'HEIGHT':
        this.isVisibleFilterHeight = false;
        break;
      case 'MODEL':
        this.isVisibleFilterModalModel = false;
        break;
      case 'OPERATIONAL_MODEL':
        this.isVisibleFilterOperationalModel = false;
        break;
      default:
        break;
    }
  }

  handleOk(field: string): void {
    switch (field) {
      case 'MANUFACTURER':
        this.isVisibleFilterModalManufacturer = false;
        break;
      case 'HEIGHT':
        this.isVisibleFilterHeight = false;
        break;
      case 'MODEL':
        this.isVisibleFilterModalModel = false;
        break;
      case 'OPERATIONAL_MODEL':
        this.isVisibleFilterOperationalModel = false;
        break;
      default:
        break;
    }
    this.applyFilter(field);
  }

  applyFilter(field: string): void {
    switch (field) {
      case 'MANUFACTURER':
        if (this.listOfSelectedManufacturerValues.length === 0) {
          switch (this.isVisibleFilterModalManufacturerStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData;
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered = this.mod2ValidatedListData;
              break;
            default:
              break;
          }
        } else {
          switch (this.isVisibleFilterModalManufacturerStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData.filter(
                  (mod2Model: Mod2Model) => {
                    return this.listOfSelectedManufacturerValues.includes(
                      mod2Model.manufacturer.name.trim()
                    );
                  }
                );
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered =
                this.mod2ValidatedListData.filter((mod2Model: Mod2Model) => {
                  return this.listOfSelectedManufacturerValues.includes(
                    mod2Model.manufacturer.name.trim()
                  );
                });
              break;
            default:
              break;
          }
        }
        break;
      case 'MODEL':
        if (this.listOfSelectedModelValues.length === 0) {
          switch (this.isVisibleFilterModalModelStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData;
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered = this.mod2ValidatedListData;
              break;
            default:
              break;
          }
        } else {
          switch (this.isVisibleFilterModalModelStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData.filter(
                  (mod2Model: Mod2Model) => {
                    return this.listOfSelectedModelValues.includes(
                      mod2Model.model.name.trim()
                    );
                  }
                );
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered =
                this.mod2ValidatedListData.filter((mod2Model: Mod2Model) => {
                  return this.listOfSelectedModelValues.includes(
                    mod2Model.model.name.trim()
                  );
                });
              break;
            default:
              break;
          }
        }
        break;
      case 'OPERATIONAL_MODEL':
        if (this.listOfSelectedOperationalValues.length === 0) {
          switch (this.isVisibleFilterOperationalModelStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData;
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered = this.mod2ValidatedListData;
              break;
            default:
              break;
          }
        } else {
          switch (this.isVisibleFilterOperationalModelStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData.filter(
                  (mod2Model: Mod2Model) => {
                    return this.listOfSelectedOperationalValues.includes(
                      mod2Model.operational_model.name.trim()
                    );
                  }
                );
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered =
                this.mod2ValidatedListData.filter((mod2Model: Mod2Model) => {
                  return this.listOfSelectedOperationalValues.includes(
                    mod2Model.operational_model.name.trim()
                  );
                });
              break;
            default:
              break;
          }
        }
        break;
      case 'HEIGHT':
        if (this.listOfSelectedHeightValues.length === 0) {
          switch (this.isVisibleFilterHeightStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData;
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered = this.mod2ValidatedListData;
              break;
            default:
              break;
          }
        } else {
          switch (this.isVisibleFilterHeightStatus) {
            case AppConstants.mod2Status.waitingForValidation.code:
              this.mod2AwaitValidationListDataFiltered =
                this.mod2AwaitValidationListData.filter(
                  (mod2Model: Mod2Model) => {
                    return this.listOfSelectedHeightValues.includes(
                      mod2Model.height.value.trim()
                    );
                  }
                );
              break;
            case AppConstants.mod2Status.validated.code:
              this.mod2ValidatedListDataFiltered =
                this.mod2ValidatedListData.filter((mod2Model: Mod2Model) => {
                  return this.listOfSelectedHeightValues.includes(
                    mod2Model.height.value.trim()
                  );
                });
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
  }

  onDetailsMod2Modal(mod2Id: string): void {
    this.router.navigate(['/mod2/details', mod2Id]);
  }

  openDeleteMod2Modal(mod2Id: string, creatorID: string): void {
    if (
      localStorage.getItem('USER_ROLE') &&
      (localStorage.getItem('USER_ROLE') == 'ADMIN' ||
        localStorage.getItem('USER_ROLE') == 'EXPERT')
    ) {
      const modal: NzModalRef = this.modal.confirm({
        nzTitle: 'Voulez vous vraiment supprimer ce Mod2 ?',
        nzContent:
          'Attention ! cette action entraineras la suppression de toutes les données du Mod2',
        nzOnOk: () => {
          this.deleteMod2(mod2Id);
        },
      });
    } else {
      if (
        localStorage.getItem('CURRENT_USER_ID') &&
        localStorage.getItem('CURRENT_USER_ID') === creatorID
      ) {
        const modal: NzModalRef = this.modal.confirm({
          nzTitle: 'Voulez vous vraiment supprimer ce Mod2 ?',
          nzContent:
            'Attention ! cette action entraineras la suppression de toutes les données du Mod2',
          nzOnOk: () => {
            this.deleteMod2(mod2Id);
          },
        });
      } else {
        const modal: NzModalRef = this.modal.confirm({
          nzTitle: "Vous n'êtes pas le créateur de ce MOD2",
          nzContent: 'ERREUR ! Vous ne pouvez supprimer ce MOD2',
          nzOnOk: () => {},
        });
      }
    }
  }

  openValidateMod2Modal(mod2Id: string, status: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: `Voulez vous vraiment ${
        status === 'VALIDATED' ? 'valider' : 'refuser'
      } ce Mod2 ?`,
      nzOnOk: () => {
        if (
          localStorage.getItem('CURRENT_USER_ID') &&
          localStorage.getItem('CURRENT_USER_ID') != ''
        ) {
          this.updateMod2Status(
            mod2Id,
            status,
            localStorage.getItem('CURRENT_USER_ID')!
          );
        }
      },
    });
  }

  updateMod2Status(mod2Id: string, status: string, validatorId: string): void {
    const loading = this.message.loading(
      `${status === 'VALIDATED' ? 'Validation' : 'Déclinaision'} en cours`,
      {
        nzDuration: 0,
      }
    ).messageId;
    this.mod2Service.updateMod2Status(mod2Id, status, validatorId).subscribe(
      (res: any) => {
        if (res.data && res.data.updateMod2.id) {
          this.deleteMod2Performnces(mod2Id);
          this.message.remove(loading);
          this.message.success(
            `Mod2 ${status === 'VALIDATED' ? 'validé' : 'décliné'} avec succès`,
            {
              nzDuration: 2500,
            }
          );
          this.getMod2s();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        console.error(err);
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  redirectToCreate(mod2Id?: string, creatorId?: string) {
    if (mod2Id) {
      if (
        localStorage.getItem('USER_ROLE') &&
        (localStorage.getItem('USER_ROLE') == 'ADMIN' ||
          localStorage.getItem('USER_ROLE') == 'EXPERT')
      ) {
        this.router.navigate(['mod2/update/', mod2Id]);
      } else {
        if (
          localStorage.getItem('CURRENT_USER_ID') &&
          localStorage.getItem('CURRENT_USER_ID') === creatorId
        ) {
          this.router.navigate(['mod2/update/', mod2Id]);
        } else {
          const modal: NzModalRef = this.modal.confirm({
            nzTitle: "Vous n'êtes pas le créateur de ce MOD2",
            nzContent: 'ERREUR ! Vous ne pouvez modifier ce MOD2',
            nzOnOk: () => {},
          });
        }
      }
    } else {
      this.router.navigate(['mod2/add']);
    }
  }
}
