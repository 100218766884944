import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = localStorage.getItem('USER_ROLE');
    if (userRole === 'ADMIN' || userRole === 'EXPERT') {
      return true;
    }

    // Redirect to login flow.
    this.router.navigate(['/']);
    return false;
  }
}
