<div class="p-4 md:p-16">
  <nav class="flex mb-4">
    <div class="flex items-center justify-end w-full">
      <button nz-button nzType="dashed" (click)="redirectToCreate()">
        <div class="flex items-center">
          <i nz-icon nzType="user-add"></i>
          <p class="m-0 ml-2">Ajouter un utilisateur</p>
        </div>
      </button>
    </div>
  </nav>
  <nz-table
    #filterTable
    [nzData]="userListDataFiltered"
    nzTableLayout="fixed"
    [nzSize]="'small'"
    nzShowSizeChanger
    [nzPageSize]="10"
    [nzBordered]="true"
  >
    <thead>
      <tr>
        <th
          nzCustomFilter
          *ngFor="let column of listOfColumns"
          [nzSortOrder]="column.sortOrder"
          [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections"
          [nzFilterMultiple]="column.filterMultiple"
          [nzFilters]="column.listOfFilter"
          [nzFilterFn]="column.filterFn"
          [nzShowFilter]="false"
          [nzShowSort]="column.name === 'Actions' ? false : true"
          [nzAlign]="column.name === 'Actions' ? 'center' : 'left'"
          nzTheme="twotone"
        >
          {{ column.name }}
          <nz-filter-trigger *ngIf="column.name === 'Rôle'">
            <i
              nz-icon
              nzType="filter"
              nzTheme="twotone"
              (click)="showFilterModal('ROLE')"
            ></i>
          </nz-filter-trigger>
          <nz-filter-trigger *ngIf="column.name === 'Email'">
            <i
              nzTheme="twotone"
              class="ant-table-filter-icon"
              nz-icon
              nz-dropdown
              #dropdown="nzDropdown"
              nzType="filter"
              [nzDropdownMenu]="menu_id_mod2"
              [class.ant-table-filter-open]="dropdown.nzVisible"
              nzTrigger="click"
              nzPlacement="bottomRight"
              [nzClickHide]="false"
              nzTableFilter
            ></i>
          </nz-filter-trigger>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of filterTable.data">
        <td>{{ data.first_name }}</td>
        <td>{{ data.last_name }}</td>
        <td>{{ data.email }}</td>
        <td>{{ data.gid }}</td>
        <td>{{ data.role.label }}</td>
        <td [nzAlign]="'center'">
          <span
            matTooltip="Afficher ou mettre à jour"
            class="
              inline-flex
              items-center
              cursor-pointer
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            (click)="redirectToCreate(data.id)"
            ><i class="bx bxs-user-detail"></i
          ></span>
          <span
            matTooltip="Supprimer"
            class="
              inline-flex
              items-center
              cursor-pointer
              justify-center
              h-12
              w-12
              text-lg text-engie-danger
            "
            (click)="openDeleteUserModal(data.id)"
            ><i class="bx bx-trash-alt"></i
          ></span>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<nz-dropdown-menu #menu_id_mod2="nzDropdownMenu">
  <div class="search-box shadow-md bg-white p-2 rounded-md">
    <input
      type="text"
      nz-input
      placeholder="Search email"
      class="
        w-full
        block
        px-2
        mb-2
        border-engie-blue border
        rounded-md
        outline-none
      "
      [(ngModel)]="searchModel"
    />
    <button
      nz-button
      nzSize="small"
      (click)="search()"
      nzType="primary"
      class="mr-1"
    >
      Search
    </button>
    <button nz-button nzSize="small" (click)="reset()">Reset</button>
  </div>
</nz-dropdown-menu>

<nz-modal
  [(nzVisible)]="isVisibleFilterModalRole"
  nzTitle="Filtre"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <div class="block">
      <p class="text-bold text-engie-blue">
        Selectionnez une ou plusieurs valeurs
      </p>
      <nz-select
        [nzMaxTagCount]="3"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="multiple"
        nzPlaceHolder="Rôles"
        [(ngModel)]="listOfSelectedRoleValues"
        class="w-2/3"
      >
        <nz-option
          *ngFor="let item of roles"
          [nzLabel]="item.label"
          [nzValue]="item.role"
        ></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder let-selectedList
        >Et {{ selectedList.length }} selectionné(s)</ng-template
      >
    </div>
  </ng-container>
</nz-modal>
