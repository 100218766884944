import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient) {}

  public addUser(userModel: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          createUser(input: {first_name:"${userModel.firstName}", last_name:"${userModel.lastName}", email:"${userModel.email}", gid:"${userModel.gid}", role_id: "${userModel.roleId}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public listUsers(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listUsers {items { id first_name last_name email gid role{
            id
            role
          }}}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteUser(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          deleteUser(input:{id:"${id}"}) {id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getUser(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          getUser(id:"${id}") { id first_name last_name email gid role{
            id
            role
          }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateUser(id: string, userModel: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        mutation{
          updateUser(input: {id:"${id}", first_name:"${userModel.firstName}", last_name:"${userModel.lastName}", email:"${userModel.email}", gid:"${userModel.gid}", role_id: "${userModel.roleId}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public setUserRole(email: any): Promise<void> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query {
          listUsers(filter: {email: {eq: "${email}"}}) {
            items {
              id
              role {
                role
                id
              }
            }
          }
        }
      `,
    };
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .post(environment.appsyncBaseUrl, body, {
          headers: header,
        })
        .toPromise()
        .then(
          (response: any) => {
            // Success
            if (response.data && response.data.listUsers.items.length > 0) {
              localStorage.setItem(
                'USER_ROLE',
                response.data.listUsers.items[0].role.role
              );
              localStorage.setItem(
                'CURRENT_USER_ID',
                response.data.listUsers.items[0].id
              );
            }
            resolve();
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
