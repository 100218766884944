import { OperationalModelModel } from 'src/app/shared/models/operational-model-model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ManufacturerModel } from 'src/app/shared/models/manufacturer-model';
import { ManufacturerService } from 'src/app/shared/services/manufacturer.service';
import { ModelService } from 'src/app/shared/services/model.service';
import { OperationalModelService } from 'src/app/shared/services/operational-model.service';
import { ModelModel } from 'src/app/shared/models/model-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Mod2Service } from 'src/app/shared/services/mod2.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { HeightService } from 'src/app/shared/services/height.service';
import { HeightModel } from 'src/app/shared/models/height-model';
import { AppConstants } from 'src/app/shared/dictionnary/appConstants';

@Component({
  selector: 'app-mod2-form-add',
  templateUrl: './mod2-form-add.component.html',
  styleUrls: ['./mod2-form-add.component.scss'],
})
export class Mod2FormAddComponent implements OnInit {
  public currentStep = 0;
  public mod2Form!: FormGroup;
  public finishStep = false;
  public manufacturerList: ManufacturerModel[] = [];
  public operationalModelList: OperationalModelModel[] = [];
  public modelModelList: ModelModel[] = [];
  public heightModelList: HeightModel[] = [];
  public isSubmitted: boolean = false;
  public currentMod2Id!: string;
  constructor(
    private manufacturerService: ManufacturerService,
    private modelService: ModelService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private mod2Service: Mod2Service,
    private message: NzMessageService,
    private router: Router,
    private operationalModelService: OperationalModelService,
    private heightService: HeightService
  ) {
    this.mod2Form = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getData();
  }

  getMod2IfPresent(): void {
    this.activatedRoute.params.subscribe((v) => {
      if (v.id) {
        this.currentMod2Id = v.id;
        this.mod2Service.getMod2(this.currentMod2Id).subscribe(
          (res) => {
            if (res.data && res.data.getMod2.id) {
              this.mod2FormControls.manufacturer.setValue(
                res.data.getMod2.manufacturer.id
              );
              this.mod2FormControls.model.setValue(res.data.getMod2.model.id);
              this.mod2FormControls.operationalModel.setValue(
                res.data.getMod2.operational_model.id
              );
              this.mod2FormControls.height.setValue(res.data.getMod2.height.id);
              this.idModChange();
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  createFormGroup() {
    return new FormGroup({
      manufacturer: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      operationalModel: new FormControl('', Validators.required),
      height: new FormControl('', Validators.required),
      mod2: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.mod2Form.valid &&
      localStorage.getItem('CURRENT_USER_ID') &&
      localStorage.getItem('CURRENT_USER_ID') != ''
    ) {
      const mod2 = {
        manufacturer: this.mod2FormControls.manufacturer.value,
        model: this.mod2FormControls.model.value,
        operationalModel: this.mod2FormControls.operationalModel.value,
        height: this.mod2FormControls.height.value,
        status: AppConstants.mod2Status.waitingForValidation.code,
        creator: localStorage.getItem('CURRENT_USER_ID'),
      };
      if (this.currentMod2Id) {
        this.mod2Service.updateMod2(this.currentMod2Id, mod2).subscribe(
          (res) => {
            if (res.data && res.data.updateMod2.id) {
              this.currentStep = 1;
              this.finishStep = true;
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
            console.log(err);
          }
        );
      } else {
        this.mod2Service.addMod2(mod2).subscribe(
          (res) => {
            if (res.data && res.data.createMod2.id) {
              this.currentStep = 1;
              this.finishStep = true;
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
            console.log(err);
          }
        );
      }
    }
  }

  idModChange() {
    const idModelValue = this.modelModelList.find(
      (item) => item.id === this.mod2Form.get('model')!.value
    )?.name!;
    const idOperationalModelValue = this.operationalModelList.find(
      (item) => item.id === this.mod2Form.get('operationalModel')!.value
    )?.name!;
    if (
      ''.trim() !== idModelValue &&
      null !== idModelValue &&
      undefined !== idModelValue &&
      ''.trim() !== idOperationalModelValue &&
      null !== idOperationalModelValue &&
      undefined !== idOperationalModelValue
    ) {
      this.mod2Form
        .get('mod2')!
        .setValue(idModelValue + '_' + idOperationalModelValue);
    }
  }

  reloadTheSamePage(): void {
    this.router
      .navigateByUrl('/mod2', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([decodeURI(this.location.path())]);
      });
  }

  getData() {
    const manufacturerObservable = this.manufacturerService.listManufacturers();
    const operationalModelObservable =
      this.operationalModelService.listOperationalModels();
    const modelObservable = this.modelService.listModels();
    const heightObservable = this.heightService.listHeights();
    forkJoin([
      manufacturerObservable,
      operationalModelObservable,
      modelObservable,
      heightObservable,
    ]).subscribe(
      (result) => {
        if (result[0].data && result[0].data.listManufacturers) {
          result[0].data.listManufacturers.items.forEach(
            (element: ManufacturerModel) => {
              this.manufacturerList.push(element);
            }
          );
        }
        if (result[1].data && result[1].data.listOperationalModels) {
          result[1].data.listOperationalModels.items.forEach(
            (element: OperationalModelModel) => {
              this.operationalModelList.push(element);
            }
          );
        }
        if (result[2].data && result[2].data.listModels) {
          result[2].data.listModels.items.forEach((element: ModelModel) => {
            this.modelModelList.push(element);
          });
        }
        if (result[3].data && result[3].data.listHeights) {
          result[3].data.listHeights.items.forEach((element: HeightModel) => {
            this.heightModelList.push(element);
          });
        }
        this.getMod2IfPresent();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  get mod2FormControls() {
    return this.mod2Form.controls;
  }
}
