import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { OperationalModelModel } from 'src/app/shared/models/operational-model-model';
import { OperationalModelService } from 'src/app/shared/services/operational-model.service';

@Component({
  selector: 'app-operational-model-list',
  templateUrl: './operational-model-list.component.html',
  styleUrls: ['./operational-model-list.component.scss'],
})
export class OperationalModelListComponent implements OnInit {
  public operationalModelList: OperationalModelModel[] = [];
  public operationalModelForm!: FormGroup;
  public isSubmitted: boolean = false;
  private currentOperationalModelId?: string;

  public isVisibleCreateModalOperationalModel = false;

  constructor(
    private operationalModelService: OperationalModelService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    this.operationalModelForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getOperationalModels();
  }

  getOperationalModels() {
    this.operationalModelList = [];
    this.operationalModelService.listOperationalModels().subscribe(
      (res) => {
        if (res.data && res.data.listOperationalModels) {
          res.data.listOperationalModels.items.forEach(
            (element: OperationalModelModel) => {
              this.operationalModelList.push(element);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisibleCreateModalOperationalModel = false;
  }

  handleOk(): void {
    this.isSubmitted = true;
    if (this.operationalModelForm.valid) {
      const operationalModel = {
        name: this.operationalModelFormControls.name.value,
      };
      if (this.currentOperationalModelId) {
        this.operationalModelService
          .updateOperationalModel(
            this.currentOperationalModelId,
            operationalModel
          )
          .subscribe(
            (res) => {
              if (res.data && res.data.updateOperationalModel.id) {
                this.message.success(
                  'Modèle opérationnel mis à jour avec succès',
                  {
                    nzDuration: 2500,
                  }
                );
                this.isVisibleCreateModalOperationalModel = false;
                this.getOperationalModels();
              } else {
                this.message.error('Une erreur est survenue', {
                  nzDuration: 2500,
                });
              }
            },
            (err) => {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
              console.log(err);
            }
          );
      } else {
        this.operationalModelService
          .addOperationalModel(operationalModel)
          .subscribe(
            (res) => {
              if (res.data && res.data.createOperationalModel.id) {
                this.message.success('Modèle opérationnel ajouté avec succès', {
                  nzDuration: 2500,
                });
                this.isVisibleCreateModalOperationalModel = false;
                this.getOperationalModels();
              } else {
                this.message.error('Une erreur est survenue', {
                  nzDuration: 2500,
                });
              }
            },
            (err) => {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
              console.log(err);
            }
          );
      }
    }
  }

  openDeleteOperationalModelModal(operationalModelId: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer ce fabriquant ?',
      nzContent: "Attention ! l'action n'est pas reverssible",
      nzOnOk: () => {
        this.deleteOperationalModel(operationalModelId);
      },
    });
  }

  deleteOperationalModel(operationalModelId: string) {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.operationalModelService
      .deleteOperationalModel(operationalModelId)
      .subscribe(
        (res) => {
          if (res.data && res.data.deleteOperationalModel.id) {
            this.message.remove(loading);
            this.message.success('Fabriquant supprimé avec succès', {
              nzDuration: 2500,
            });
            this.getOperationalModels();
          } else {
            this.message.remove(loading);
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
          }
        },
        (err) => {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      );
  }

  createFormGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  showCreateModal(operationelModelModel?: OperationalModelModel) {
    if (operationelModelModel) {
      this.currentOperationalModelId = operationelModelModel.id;
      this.operationalModelFormControls.name.setValue(
        operationelModelModel.name
      );
    } else {
      this.currentOperationalModelId = undefined;
      this.operationalModelFormControls.name.setValue('');
    }
    this.isVisibleCreateModalOperationalModel = true;
  }

  get operationalModelFormControls() {
    return this.operationalModelForm.controls;
  }
}
