// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appsyncBaseUrl:
    'https://7azto63dufetpmu2yfpi3pbe7i.appsync-api.eu-west-1.amazonaws.com/graphql',
  appsyncAapiKey: 'da2-i7ebmnhh7rfhbdbuvatt3k5koe',
  CLIENT_ID: '0oa39p2vk4mMWO9VC5d7',
  ISSUER: 'https://dev-18958159.okta.com',
  LOGIN_REDIRECT_URI: 'https://stg.wtg.noprod.myfrren.com/login/callback',
  LOGOUT_REDIRECT_URI: 'https://stg.wtg.noprod.myfrren.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
