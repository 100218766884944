import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AppConstants } from 'src/app/shared/dictionnary/appConstants';
import { Mod2Model } from 'src/app/shared/models/mod2-model';
import { Mod2PerformancesModel } from 'src/app/shared/models/mod2-performances-model';
import { Mod2Service } from 'src/app/shared/services/mod2.service';

@Component({
  selector: 'app-mod2-details',
  templateUrl: './mod2-details.component.html',
  styleUrls: ['./mod2-details.component.scss'],
})
export class Mod2DetailsComponent implements OnInit {
  public currentMod2Id!: string;
  public currentMod2: Mod2Model[] = [];
  public currentMod2PerformanceList: Mod2PerformancesModel[] = [];
  public editCache: {
    [key: string]: { edit: boolean; data: Mod2PerformancesModel };
  } = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private mod2Service: Mod2Service,
    private modal: NzModalService,
    private message: NzMessageService
  ) {
    this.activatedRoute.params.subscribe((v) => {
      if (v.id) {
        this.currentMod2Id = v.id;
      }
    });
  }

  ngOnInit(): void {
    this.getMod2IfPresent(this.currentMod2Id);
  }

  addNewLine() {
    if (
      localStorage.getItem('USER_ROLE') &&
      (localStorage.getItem('USER_ROLE') == 'ADMIN' ||
        localStorage.getItem('USER_ROLE') == 'EXPERT')
    ) {
      const mod2PerformanceLine = {
        id: '',
        vhh: 0,
        generated_power: 0,
        coefficient_trust: 0,
        lw_global: 0,
        lw_63_hz: 0,
        lw_125_hz: 0,
        lw_250_hz: 0,
        lw_500_hz: 0,
        lw_1_khz: 0,
        lw_2_khz: 0,
        lw_4_khz: 0,
        lw_8_khz: 0,
        mod2_id: this.currentMod2Id,
      };
      this.mod2Service.createMod2Performances(mod2PerformanceLine).subscribe(
        (result) => {
          if (result.data && result.data.createMod2Performances.id) {
            mod2PerformanceLine.id = result.data.createMod2Performances.id;
            this.currentMod2PerformanceList.push(mod2PerformanceLine);
            this.currentMod2PerformanceList = [
              ...this.currentMod2PerformanceList,
            ];
            this.updateEditCache();
          }
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      if (
        localStorage.getItem('CURRENT_USER_ID') &&
        localStorage.getItem('CURRENT_USER_ID') ===
          this.currentMod2[0].creator.id
      ) {
        const mod2PerformanceLine = {
          id: '',
          vhh: 0,
          generated_power: 0,
          coefficient_trust: 0,
          lw_global: 0,
          lw_63_hz: 0,
          lw_125_hz: 0,
          lw_250_hz: 0,
          lw_500_hz: 0,
          lw_1_khz: 0,
          lw_2_khz: 0,
          lw_4_khz: 0,
          lw_8_khz: 0,
          mod2_id: this.currentMod2Id,
        };
        this.mod2Service.createMod2Performances(mod2PerformanceLine).subscribe(
          (result) => {
            if (result.data && result.data.createMod2Performances.id) {
              mod2PerformanceLine.id = result.data.createMod2Performances.id;
              this.currentMod2PerformanceList.push(mod2PerformanceLine);
              this.currentMod2PerformanceList = [
                ...this.currentMod2PerformanceList,
              ];
              this.updateEditCache();
            }
          },
          (err) => {
            console.error(err);
          }
        );
      } else {
        const modal: NzModalRef = this.modal.confirm({
          nzTitle: "Vous n'êtes pas le créateur de ce MOD2",
          nzContent: 'ERREUR ! Vous ne pouvez modifier ce MOD2',
          nzOnOk: () => {},
        });
      }
    }
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.currentMod2PerformanceList.findIndex(
      (item) => item.id === id
    );
    this.editCache[id] = {
      data: { ...this.currentMod2PerformanceList[index] },
      edit: false,
    };
  }

  saveEdit(id: string): void {
    const loading = this.message.loading('Mise à jour en cours', {
      nzDuration: 0,
    }).messageId;

    this.mod2Service
      .updateMod2Performances(id, this.editCache[id].data)
      .subscribe(
        (res) => {
          if (res.data && res.data.updateMod2Performances.id) {
            this.message.remove(loading);
            this.message.success('Ligne mise à jour avec succès', {
              nzDuration: 2500,
            });
            this.getMod2Performnces(this.currentMod2Id);
          } else {
            this.message.remove(loading);
            this.message.error('Une erreur est survenue', { nzDuration: 2500 });
          }
        },
        (err) => {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      );
  }

  openDeleteMod2PerformanceModal(id: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer cette ligne ?',
      nzContent: 'Attention ! cette action est irréversible',
      nzOnOk: () => {
        this.deleteMod2Performance(id);
      },
    });
  }

  deleteMod2Performance(id: string): void {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.mod2Service.deleteMod2Performances(id).subscribe(
      (res) => {
        if (res.data && res.data.deleteMod2Performances.id) {
          this.message.remove(loading);
          this.message.success('Ligne supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getMod2Performnces(this.currentMod2Id);
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  updateEditCache(): void {
    this.editCache = {};
    this.currentMod2PerformanceList.forEach((item) => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item },
      };
    });
  }

  getMod2IfPresent(id: string): void {
    this.currentMod2 = [];
    this.mod2Service.getMod2(id).subscribe(
      (res) => {
        if (res.data && res.data.getMod2) {
          switch (res.data.getMod2.status) {
            case AppConstants.mod2Status.waitingForValidation.code:
              res.data.getMod2.status_label =
                AppConstants.mod2Status.waitingForValidation.label;
              break;
            case AppConstants.mod2Status.validated.code:
              res.data.getMod2.status_label =
                AppConstants.mod2Status.validated.label;
              break;
            default:
              break;
          }
          this.currentMod2.push(res.data.getMod2);
          this.currentMod2 = [...this.currentMod2];
          this.currentMod2[0].createdAt = moment(
            new Date(this.currentMod2[0].createdAt)
          )
            .locale('fr')
            .format('DD MMMM YYYY à HH:mm:ss');
          this.getMod2Performnces(id);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getMod2Performnces(id: string): void {
    this.currentMod2PerformanceList = [];
    this.mod2Service.listMod2Performances(id).subscribe(
      (res) => {
        if (res.data && res.data.listMod2Performances) {
          res.data.listMod2Performances.items.forEach(
            (element: Mod2PerformancesModel) => {
              this.currentMod2PerformanceList.push(element);
              this.currentMod2PerformanceList = [
                ...this.currentMod2PerformanceList,
              ];
            }
          );
          this.updateEditCache();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
