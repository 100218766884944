import { AuhtGuard } from './shared/guards/auht.guard';
import { Mod2DetailsComponent } from './mod2/mod2-details/mod2-details.component';
import { OperationalModelListComponent } from './operational-models/operational-model-list/operational-model-list.component';
import { ManufacturerListComponent } from './manufacturers/manufacturer-list/manufacturer-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { ModelListComponent } from './models/model-list/model-list.component';
import { HeightListComponent } from './heights/height-list/height-list.component';
import { Mod2FormAddComponent } from './mod2/mod2-form-add/mod2-form-add.component';
import { Mod2ListComponent } from './mod2/mod2-list/mod2-list.component';
import { CallbackComponent } from './callback/callback.component';
import { AdminGuard } from './shared/guards/admin.guard';

const routes: Routes = [
  {
    path: 'login/callback',
    component: CallbackComponent,
  },
  {
    path: '',
    canActivate: [AuhtGuard],
    children: [
      {
        path: 'users',
        canActivate: [AdminGuard],
        children: [
          {
            path: '',
            component: UserListComponent,
          },
          {
            path: 'add',
            component: UserAddComponent,
          },
          {
            path: 'update/:id',
            component: UserAddComponent,
          },
        ],
      },
      {
        path: 'mod2',
        children: [
          {
            path: '',
            component: Mod2ListComponent,
          },
          {
            path: 'add',
            component: Mod2FormAddComponent,
          },
          {
            path: 'update/:id',
            component: Mod2FormAddComponent,
          },
          {
            path: 'details/:id',
            component: Mod2DetailsComponent,
          },
        ],
      },
      {
        path: 'manufacturers',
        children: [
          {
            path: '',
            component: ManufacturerListComponent,
          },
        ],
      },
      {
        path: 'operational-models',
        children: [
          {
            path: '',
            component: OperationalModelListComponent,
          },
        ],
      },
      {
        path: 'models',
        children: [
          {
            path: '',
            component: ModelListComponent,
          },
        ],
      },
      {
        path: 'heights',
        children: [
          {
            path: '',
            component: HeightListComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
