<div class="w-full h-full p-8">
  <div class="w-full md:w-full xl:w-1/2 mx-auto my-8">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step
        [nzTitle]="
          currentMod2Id ? 'Modifier un MOD2' : 'Ajouter un nouveau MOD2'
        "
      ></nz-step>
      <nz-step nzTitle="Résumé"></nz-step>
    </nz-steps>
  </div>
  <form
    [formGroup]="mod2Form"
    (ngSubmit)="onSubmit()"
    novalidate
    *ngIf="!finishStep"
  >
    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="manufacturer">Fabriquant: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <mat-select
          formControlName="manufacturer"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md pt-1"
          [ngClass]="
            isSubmitted && mod2FormControls.manufacturer.errors?.required
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        >
          <mat-option
            *ngFor="let manufacturer of manufacturerList"
            [value]="manufacturer.id"
            >{{ manufacturer.name }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && mod2FormControls.manufacturer.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le fabriquant est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="operationalModel">Modèle opérationnel: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <mat-select
          formControlName="operationalModel"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md pt-1"
          (ngModelChange)="idModChange()"
          [ngClass]="
            isSubmitted && mod2FormControls.operationalModel.errors?.required
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        >
          <mat-option
            *ngFor="let operationalModel of operationalModelList"
            [value]="operationalModel.id"
            >{{ operationalModel.name }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="
          isSubmitted && mod2FormControls.operationalModel.errors?.required
        "
        class="text-engie-danger text-xs italic"
      >
        Le modèle opérationnel est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="height">Heuteur moyeu: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <mat-select
          formControlName="height"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md pt-1"
          (ngModelChange)="idModChange()"
          [ngClass]="
            isSubmitted && mod2FormControls.height.errors?.required
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        >
          <mat-option
            *ngFor="let heightModel of heightModelList"
            [value]="heightModel.id"
            >{{ heightModel.value }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="
          isSubmitted && mod2FormControls.height.errors?.required
        "
        class="text-engie-danger text-xs italic"
      >
        La hauteur du moyeu est requise.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="model">Modèle: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <mat-select
          formControlName="model"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md pt-1"
          (ngModelChange)="idModChange()"
          [ngClass]="
            isSubmitted && mod2FormControls.model.errors?.required
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        >
          <mat-option
            *ngFor="let modelModel of modelModelList"
            [value]="modelModel.id"
            >{{ modelModel.name }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && mod2FormControls.model.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le modèle est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="mod2">MOD2: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <input
          placeholder="MOD2"
          formControlName="mod2"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && mod2Form.get('mod2')?.invalid
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
          readonly
        />
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && mod2FormControls.mod2.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le mod2 est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <button
        class="
          bg-engie-blue
          hover-bg-engie-blue
          text-white
          font-bold
          py-2
          px-4
          rounded
          inline-flex
          items-center
        "
        type="submit"
      >
        Enregistrer
      </button>
    </div>
  </form>

  <div class="w-full md:w-full xl:w-1/2 mx-auto my-8" *ngIf="finishStep">
    <nz-result
      nzStatus="success"
      [nzTitle]="
        currentMod2Id
          ? 'MOD2 mis à jour avec succès'
          : 'MOD2 ajouté avec succès'
      "
      [nzSubTitle]="
        currentMod2Id
          ? ''
          : 'Vous pouvez créer de nouveaux MOD2 en cliquant sur le bouton [Ajouter un nouveau]'
      "
    >
      <div nz-result-extra>
        <button nz-button nzType="primary" routerLink="/mod2">
          Liste MOD2
        </button>
        <button nz-button (click)="reloadTheSamePage()" *ngIf="!currentMod2Id">
          Ajouter un nouveau
        </button>
      </div>
    </nz-result>
  </div>
</div>
