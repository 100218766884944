<div class="w-full h-full p-8">
  <div class="w-full md:w-1/2 xl:w-1/3 mx-auto my-8">
    <p class="w-full text-center text-xl">Création d'utilisateur</p>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate>
    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="firstName">Prénom: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <input
          placeholder="Saisir le prénom de l'utilisateur"
          formControlName="firstName"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('firstName')?.invalid
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        />
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && userFormControls.firstName.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le prénom est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="lastName">Nom: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <input
          placeholder="Saisir le nom de l'utilisateur"
          formControlName="lastName"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('lastName')?.invalid
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        />
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && userFormControls.lastName.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le nom est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="email">Email: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <input
          placeholder="Saisir l'adresse e-mail de l'utilisateur"
          formControlName="email"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('email')?.invalid
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        />
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && userFormControls.email.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        L'adresse email est requise.
      </p>
      <p
        *ngIf="isSubmitted && userFormControls.email.errors?.pattern"
        class="text-engie-danger text-xs italic"
      >
        L'adresse email n'est pas valide (le domaine doit être @engie.com ou
        @external.engie.com).
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="gid">GID: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <input
          placeholder="Saisir le GID de l'utilisateur"
          formControlName="gid"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('gid')?.invalid
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        />
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && userFormControls.gid.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le GID est requis.
      </p>
      <p
        *ngIf="isSubmitted && userFormControls.gid.errors?.pattern"
        class="text-engie-danger text-xs italic"
      >
        Le GID n'est pas valide.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <div class="w-24">
        <label for="role">Rôle: </label>
      </div>
      <div class="w-full md:w-full xl:pl-4">
        <mat-select
          formControlName="role"
          class="w-full h-8 border-2 rounded-md outline-none px-2 text-md pt-1"
          [ngClass]="
            isSubmitted && userFormControls.role.errors?.required
              ? 'border-engie-danger'
              : 'border-engie-blue'
          "
        >
          <mat-option *ngFor="let role of roles" [value]="role.id">{{
            role.label
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div
      class="
        w-full
        md:w-1/2
        2xl:w-1/3
        mx-auto
        block
        xl:flex xl:items-center
        h-10
      "
    >
      <p
        *ngIf="isSubmitted && userFormControls.role.errors?.required"
        class="text-engie-danger text-xs italic"
      >
        Le rôle est requis.
      </p>
    </div>

    <div
      class="w-full md:w-1/2 2xl:w-1/3 mx-auto block xl:flex xl:items-center"
    >
      <button
        class="
          bg-engie-blue
          hover-bg-engie-blue
          text-white
          font-bold
          py-2
          px-4
          rounded
          inline-flex
          items-center
        "
        type="submit"
      >
        Sauvegarder
      </button>
    </div>
  </form>
</div>
