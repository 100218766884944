import { RoleCheck } from './shared/helpers/role-check';
import { ModelListComponent } from './models/model-list/model-list.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material-module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { ZorroModule } from './shared/modules/zorro-module';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ManufacturerListComponent } from './manufacturers/manufacturer-list/manufacturer-list.component';
import { OperationalModelListComponent } from './operational-models/operational-model-list/operational-model-list.component';
import { HeightListComponent } from './heights/height-list/height-list.component';
import { Mod2FormAddComponent } from './mod2/mod2-form-add/mod2-form-add.component';
import { Mod2ListComponent } from './mod2/mod2-list/mod2-list.component';
import { Mod2DetailsComponent } from './mod2/mod2-details/mod2-details.component';
import { CallbackComponent } from './callback/callback.component';

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
    UserAddComponent,
    UserListComponent,
    MenuComponent,
    HeaderComponent,
    ManufacturerListComponent,
    OperationalModelListComponent,
    ModelListComponent,
    HeightListComponent,
    Mod2FormAddComponent,
    Mod2ListComponent,
    Mod2DetailsComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ZorroModule,
    NgxChartsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: fr_FR },
    NzMessageService,
    RoleCheck,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
