import { RoleService } from './../../shared/services/role.service';
import { Component, OnInit } from '@angular/core';
import { UsersColumnItem } from 'src/app/shared/Interfaces/users-column-interface';
import { UserModel } from 'src/app/shared/models/user-model';
import { UserService } from 'src/app/shared/services/user.service';
import { RoleModel } from 'src/app/shared/models/role-model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  userListDataFiltered: UserModel[] = [];
  userListData: UserModel[] = [];
  roles: RoleModel[] = [];

  // SEARCH OPTIONS
  searchModel: string = '';

  // ROLE FILTER OPTIONS
  isVisibleFilterModalRole = false;
  listOfSelectedRoleValues: string[] = [];

  // TABLE COLUMNS OPTIONS
  listOfColumns: UsersColumnItem[] = [
    {
      name: 'Prénom',
      sortOrder: null,
      sortFn: (a: UserModel, b: UserModel) =>
        a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Nom',
      sortOrder: null,
      sortFn: (a: UserModel, b: UserModel) =>
        a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Email',
      sortOrder: null,
      sortFn: (a: UserModel, b: UserModel) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'GID',
      sortOrder: null,
      sortFn: (a: UserModel, b: UserModel) => a.gid.localeCompare(b.gid),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Rôle',
      sortOrder: null,
      sortFn: (a: UserModel, b: UserModel) =>
        a.role.role.localeCompare(b.role.role),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
    {
      name: 'Actions',
      sortOrder: null,
      sortFn: null,
      sortDirections: [],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
    },
  ];
  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private modal: NzModalService,
    private router: Router,
    private message: NzMessageService
  ) {
    this.getRoles();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  reset(): void {
    this.userListDataFiltered = this.userListData;
  }

  search(): void {
    if (this.searchModel.trim() != '') {
      this.userListDataFiltered = this.userListData.filter(
        (userModel: UserModel) => {
          return userModel.email
            .toLocaleLowerCase()
            .trim()
            .includes(this.searchModel.toLocaleLowerCase().trim());
        }
      );
    }
  }

  getUsers(): void {
    this.userListData = [];
    this.userListDataFiltered = [];
    this.userService.listUsers().subscribe(
      (res) => {
        if (res.data && res.data.listUsers) {
          res.data.listUsers.items.forEach((element: UserModel) => {
            switch (element.role.role) {
              case 'ADMIN':
                element.role.label = 'Administrateur';
                break;
              case 'USER':
                element.role.label = 'Utilisateur';
                break;
              case 'EXPERT':
                element.role.label = 'Expert';
                break;
              default:
                break;
            }
            this.userListDataFiltered.push(element);
          });
          this.userListDataFiltered = [...this.userListDataFiltered];
          this.userListData = this.userListDataFiltered;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRoles() {
    this.roleService.listUserRoles().subscribe(
      (res) => {
        if (res.data && res.data.listUserRoles) {
          res.data.listUserRoles.items.forEach((element: RoleModel) => {
            switch (element.role) {
              case 'ADMIN':
                element.label = 'Administrateur';
                break;
              case 'USER':
                element.label = 'Utilisateur';
                break;
              case 'EXPERT':
                element.label = 'Expert';
                break;
              default:
                break;
            }
            this.roles.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showFilterModal(field: string): void {
    if (field === 'ROLE') {
      this.isVisibleFilterModalRole = true;
    }
  }

  handleOk(): void {
    this.isVisibleFilterModalRole = false;
    this.applyFilter();
  }

  applyFilter(): void {
    if (this.listOfSelectedRoleValues.length === 0) {
      this.userListDataFiltered = this.userListData;
    } else {
      this.userListDataFiltered = this.userListData.filter(
        (userModel: UserModel) => {
          return this.listOfSelectedRoleValues.includes(
            userModel.role.role.trim()
          );
        }
      );
    }
  }

  handleCancel(): void {
    this.isVisibleFilterModalRole = false;
  }

  openDeleteUserModal(userId: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer cet utlisateur ?',
      nzContent: "Attention ! l'action n'est pas reverssible",
      nzOnOk: () => {
        this.deleteUser(userId);
      },
    });
  }

  deleteUser(userId: string) {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.userService.deleteUser(userId).subscribe(
      (res) => {
        if (res.data && res.data.deleteUser.id) {
          this.message.remove(loading);
          this.message.success('Utilisateur supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getUsers();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  redirectToCreate(userId?: string) {
    if (userId) {
      this.router.navigate(['users/update/', userId]);
    } else {
      this.router.navigate(['users/add']);
    }
  }
}
