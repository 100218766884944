import { ModelService } from 'src/app/shared/services/model.service';
import { ModelModel } from 'src/app/shared/models/model-model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss'],
})
export class ModelListComponent implements OnInit {
  public modelList: ModelModel[] = [];
  public modelForm!: FormGroup;
  public isSubmitted: boolean = false;
  private currentModelId?: string;

  public isVisibleCreateModalModel = false;

  constructor(
    private modelService: ModelService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    this.modelForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getModels();
  }

  getModels() {
    this.modelList = [];
    this.modelService.listModels().subscribe(
      (res) => {
        if (res.data && res.data.listModels) {
          res.data.listModels.items.forEach((element: ModelModel) => {
            this.modelList.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisibleCreateModalModel = false;
  }

  handleOk(): void {
    this.isSubmitted = true;
    if (this.modelForm.valid) {
      const model = {
        name: this.modelFormControls.name.value,
      };
      if (this.currentModelId) {
        this.modelService.updateModel(this.currentModelId, model).subscribe(
          (res) => {
            if (res.data && res.data.updateModel.id) {
              this.message.success('Modèle mis à jour avec succès', {
                nzDuration: 2500,
              });
              this.isVisibleCreateModalModel = false;
              this.getModels();
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', {
              nzDuration: 2500,
            });
            console.log(err);
          }
        );
      } else {
        this.modelService.addModel(model).subscribe(
          (res) => {
            if (res.data && res.data.createModel.id) {
              this.message.success('Modèle ajouté avec succès', {
                nzDuration: 2500,
              });
              this.isVisibleCreateModalModel = false;
              this.getModels();
            } else {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            this.message.error('Une erreur est survenue', {
              nzDuration: 2500,
            });
            console.log(err);
          }
        );
      }
    }
  }

  openDeleteModelModal(modelId: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer ce fabriquant ?',
      nzContent: "Attention ! l'action n'est pas reverssible",
      nzOnOk: () => {
        this.deleteModel(modelId);
      },
    });
  }

  deleteModel(modelId: string) {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.modelService.deleteModel(modelId).subscribe(
      (res) => {
        if (res.data && res.data.deleteModel.id) {
          this.message.remove(loading);
          this.message.success('Modèle supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getModels();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  showCreateModal(modelModel?: ModelModel) {
    if (modelModel) {
      this.currentModelId = modelModel.id;
      this.modelFormControls.name.setValue(modelModel.name);
    } else {
      this.currentModelId = undefined;
      this.modelFormControls.name.setValue('');
    }
    this.isVisibleCreateModalModel = true;
  }

  get modelFormControls() {
    return this.modelForm.controls;
  }
}
