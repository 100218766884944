import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(public http: HttpClient) {}

  public listUserRoles(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listUserRoles {items {id role }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
