<div class="p-4 md:p-16">
  <div class="2xl:w-1/2 mx-auto">
    <nav class="flex mb-4">
      <div class="flex items-center justify-start w-full">
        <button nz-button nzType="dashed" (click)="showCreateModal()">
          <div class="flex items-center">
            <i class="bx bx-grid"></i>
            <p class="m-0 ml-2">Ajouter un modèle</p>
          </div>
        </button>
      </div>
    </nav>
    <div
      class="grid grid-cols-1 gap-4 w-full"
      [ngClass]="{
        'md:grid-cols-1': modelList.length === 1,
        'md:grid-cols-2': modelList.length === 2,
        'md:grid-cols-3': modelList.length >= 3,
        'xl:grid-cols-4': modelList.length >= 4
      }"
    >
      <nz-card
        class="bg-white w-full shadow-md rounded-lg"
        *ngFor="let model of modelList"
        [nzActions]="[actionEdit, actionEllipsis]"
      >
        <nz-skeleton
          [nzActive]="true"
          [nzLoading]="false"
          [nzAvatar]="{ size: 'large' }"
        >
          <nz-card-meta [nzTitle]="model.name"></nz-card-meta>
        </nz-skeleton>
        <ng-template #actionEdit>
          <span
            matTooltip="Afficher ou mettre à jour"
            class="
              inline-flex
              items-center
              cursor-pointer
              justify-center
              h-12
              w-12
              text-lg text-engie-blue
            "
            (click)="showCreateModal(model)"
            ><i class="bx bx-detail text-xl"></i
          ></span>
        </ng-template>

        <ng-template #actionEllipsis>
          <span
            matTooltip="Supprimer"
            class="
              inline-flex
              items-center
              cursor-pointer
              justify-center
              h-12
              w-12
              text-lg text-engie-danger
            "
            (click)="openDeleteModelModal(model.id)"
            ><i class="bx bx-trash-alt text-xl"></i
          ></span>
        </ng-template>
      </nz-card>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleCreateModalModel"
  nzTitle="Ajouter un fabriquant"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <div class="block">
      <form [formGroup]="modelForm" novalidate>
        <div class="w-full mx-auto block xl:flex xl:items-center">
          <div class="w-24">
            <label for="name">Nom: </label>
          </div>
          <div class="w-full xl:pl-4">
            <input
              placeholder="Saisir le nom du modèle"
              formControlName="name"
              class="w-full h-8 border-2 rounded-md outline-none px-2 text-md"
              [ngClass]="
                isSubmitted && modelForm.get('name')?.invalid
                  ? 'border-engie-danger'
                  : 'border-engie-blue'
              "
            />
          </div>
        </div>
        <div class="w-full mx-auto block xl:flex xl:items-center h-10">
          <p
            *ngIf="isSubmitted && modelFormControls.name.errors?.required"
            class="text-engie-danger text-xs italic"
          >
            Le nom est requis.
          </p>
        </div>
      </form>
    </div>
  </ng-container>
</nz-modal>
