import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { HeightModel } from 'src/app/shared/models/height-model';
import { HeightService } from 'src/app/shared/services/height.service';

@Component({
  selector: 'app-height-list',
  templateUrl: './height-list.component.html',
  styleUrls: ['./height-list.component.scss'],
})
export class HeightListComponent implements OnInit {
  public heightList: HeightModel[] = [];
  public heightForm!: FormGroup;
  public isSubmitted: boolean = false;
  private currentHeightId?: string;

  public isVisibleCreateModalHeight = false;

  constructor(
    private heightService: HeightService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    this.heightForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getHeights();
  }

  getHeights() {
    this.heightList = [];
    this.heightService.listHeights().subscribe(
      (res) => {
        if (res.data && res.data.listHeights) {
          res.data.listHeights.items.forEach(
            (element: HeightModel) => {
              this.heightList.push(element);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisibleCreateModalHeight = false;
  }

  handleOk(): void {
    this.isSubmitted = true;
    if (this.heightForm.valid) {
      const height = {
        value: this.heightFormControls.value.value,
      };
      if (this.currentHeightId) {
        this.heightService
          .updateHeight(this.currentHeightId, height)
          .subscribe(
            (res) => {
              if (res.data && res.data.updateHeight.id) {
                this.message.success('Hauteur mis à jour avec succès', {
                  nzDuration: 2500,
                });
                this.isVisibleCreateModalHeight = false;
                this.getHeights();
              } else {
                console.error("updateHeight: no data")
                this.message.error('Une erreur est survenue', {
                  nzDuration: 2500,
                });
              }
            },
            (err) => {
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
              console.log(err);
            }
          );
      } else {
        this.heightService
          .addHeight(height)
          .subscribe(
          (res) => {
            if (res.data && res.data.createHeight.id) {
              this.message.success('Hauteur ajoutée avec succès', {
                nzDuration: 2500,
              });
              this.isVisibleCreateModalHeight = false;
              this.getHeights();
            } else {
              console.error("addHeight: no data")
              this.message.error('Une erreur est survenue', {
                nzDuration: 2500,
              });
            }
          },
          (err) => {
            console.error((err as Error).message)
            this.message.error('Une erreur est survenue', {
              nzDuration: 2500,
            });
            console.log(err);
          }
        );
      }
    }
  }

  openDeleteHeightModal(HeightId: string): void {
    const modal: NzModalRef = this.modal.confirm({
      nzTitle: 'Voulez vous supprimer cette hauteur ?',
      nzContent: "Attention ! l'action n'est pas reverssible",
      nzOnOk: () => {
        this.deleteHeight(HeightId);
      },
    });
  }

  deleteHeight(HeightId: string) {
    const loading = this.message.loading('Suppression en cours', {
      nzDuration: 0,
    }).messageId;
    this.heightService.deleteHeight(HeightId).subscribe(
      (res) => {
        if (res.data && res.data.deleteHeight.id) {
          this.message.remove(loading);
          this.message.success('Hauteur supprimé avec succès', {
            nzDuration: 2500,
          });
          this.getHeights();
        } else {
          this.message.remove(loading);
          this.message.error('Une erreur est survenue', { nzDuration: 2500 });
        }
      },
      (err) => {
        this.message.remove(loading);
        this.message.error('Une erreur est survenue', { nzDuration: 2500 });
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      value: new FormControl('', Validators.required),
    });
  }

  showCreateModal(heightModel?: HeightModel) {
    if (heightModel) {
      this.currentHeightId = heightModel.id;
      this.heightFormControls.value.setValue(heightModel.value);
    } else {
      this.currentHeightId = undefined;
      this.heightFormControls.value.setValue('');
    }
    this.isVisibleCreateModalHeight = true;
  }

  get heightFormControls() {
    return this.heightForm.controls;
  }
}

